.ToTopButton
  width: 64px
  height: 64px
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  background-color: rgba(0,0,0,0.7)
  position: fixed
  bottom: 96px
  right: 16px
  font-size: 24px
  cursor: pointer
  transition: all 0.3s linear
  &:hover
    background-color: var(--activeGray)
    color: #fff
    &::after
      top: 0
      background-color: var(--activeGray)
  &:active
    &:after
      color: #fff !important
      background: var(--lightGray)

@media screen and ( max-width: 576px )
  .ToTopButton
    width: 48px
    height: 48px
    right: 24px
