.ContactUsPopUp
  h5
    display: none
  .ContactUs-Form
    margin-top: 24px
    max-width: 100%
@media screen and ( max-width: 576px )
  .ContactUsPopUp
    .ContactUs-Form
      max-width: 100% !important
