.Portfolio
  width: 100%
  background: linear-gradient(to right, #ffffff 0% 10%, #F6F7F8 10% 90%, #ffffff 90% 100%)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  .SettingsButton
    margin-left: 16px
    .Button-IconContainer
      margin-right: 0
.Portfolio-NavContainer
  display: flex
  justify-content: center
  margin: 64px 0px
.Portfolio-Nav
  display: grid
  grid-template-columns: repeat(5, max-content)
  grid-gap: 32px
.Portfolio-NavLink
  font-size: 14px
  text-transform: uppercase
  color: var(--darkGray)
  font-weight: 700
  position: relative
  overflow: hidden
  padding-bottom: 8px
  transition: all .2s ease-in-out
  cursor: pointer
  &::after
    content: ''
    display: block
    width: 30px
    height: 2px
    background-color: #000
    border-radius: 2px
    position: absolute
    transition: all .2s ease-in-out
    bottom: 0
    left: -30px
  &:hover
    color: #000
    &::after
      left: 0

.Portfolio-NavLink_theme_active
  color: #000
  &::after
    left: 0

.Portfolio-Skeleton
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 24px
  height: 390px
  width: 100%

@media screen and ( min-width: 1400px )
  .Protfolio-Container
    max-width: 1400px !important
    padding: 0 !important

@media screen and ( max-width: 1024px )
  .Portfolio-NavContainer
    margin: 48px 0px
  .Portfolio-Nav
    grid-template-columns: 1fr
    grid-gap: 24px
    justify-items: center
  .Portfolio-Skeleton
    grid-template-columns: repeat(2, 1fr)

@media screen and ( max-width: 576px )
  .Portfolio-Skeleton
    grid-template-columns: 1fr
