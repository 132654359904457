.Boutique
  background-color: #000
  width: 100%
  color: #fff
  justify-content: center
  display: flex
  flex-direction: column
  align-items: center
  .ProductManager
    max-width: 800px
  .Portfolio-NavLink
    &::after
      background-color: var(--lightGray)
    &:hover
      color: #fff
  .Portfolio-NavLink_theme_active
    color: var(--lightGray)

.Boutique-Catalog
  display: grid
  grid-template-columns: repeat(4, minmax(0, 1fr))
  grid-gap: 48px

@media screen and ( max-width: 1024px )
  .Boutique
    .Portfolio-Nav
      grid-template-columns: repeat(3, max-content)
  .Boutique-Catalog
    grid-template-columns: repeat(3, minmax(0, 1fr))

@media screen and ( max-width: 768px )
  .Boutique
    .Portfolio-Nav
      grid-template-columns: 1fr
  .Boutique-Catalog
    grid-template-columns: repeat(2, minmax(0, 1fr))

@media screen and ( max-width: 576px )
  .Boutique-Catalog
    grid-template-columns: 1fr
