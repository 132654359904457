.ProjectSmallTile
  color: #000
  padding: 8px 16px
  border: 1px var(--darkGray) solid
  cursor: pointer
.PositionPopUp-ElementGrid
  margin-top: 16px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
