.PhotoManager
  display: grid
  grid-gap: 24px
  grid-template-columns: 1fr
.PhotoManager-PhotoGrid
  display: grid
  grid-gap: 24px
  grid-template-columns: repeat(auto-fit, minmax(240px, 320px))
  grid-auto-rows: 420px
  .DragAndDrop-Zone
    grid-column: 1/-1
.DragAndDrop-Zone
  width: 100%
  height: 100%
  min-height: 120px
  background-color: #000
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding: 8px
  color: #fff
  cursor: pointer
  outline: none
  appearance: none
  span
    text-decoration: underline
