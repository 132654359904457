.Site-CookieNotification
  width: calc(50% - 32px)
  margin-left: 16px
  padding: 16px 24px
  background-color: rgba(0,0,0, 0.8)
  position: sticky
  bottom: 16px
  z-index: 1000
  .CookieNotification-Container
    display: grid
    grid-template-columns: 1fr 24px
    grid-gap: 16px
    align-items: center
  .CookieNotification-Icon
    width: 64px
    align-self: self-start
  .CookieNotification-Text
    color: #fff
    font-size: 12px
    line-height: 22px
    a
      color: var(--activeGray)
      &:hover
        text-decoration: underline !important
  .CookieNotification-Title
    font-size: 16px
    font-weight: 600
    margin-bottom: 8px
  .CookieNotification-Button
    width: 24px
    height: 24px
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    background-color: rgba(0,0,0,0.7)
    cursor: pointer
    &:hover
      background-color: var(--accent)

@media screen and ( max-width: 576px )
  .Site-CookieNotification
    width: calc(100% - 32px)
    bottom: 96px
