.ContactUs
  background-color: #000
  color: #fff
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
.ContactUs-Container
  margin-top: 64px
  display: grid
  grid-template-columns: repeat(2, max-content)
  grid-gap: 64px
  .ContactUs-Links
    display: grid
    grid-template-columns: repeat(2, max-content)
    grid-gap: 24px
  .SocialBlock
    font-size: 16px
    a
      &:not(:last-child)
        margin-right: 12px
  .ContactUs-Map
    margin-top: 64px
    width: 700px
    h5
      font-size: 24px
      margin-bottom: 0
    p
      font-size: 14px
      color: var(--darkGray)
      margin-bottom: 24px
    .Map
      height: 350px

.ContactUsInfoTile
  display: flex
  align-items: center
  .ContactUsInfoTile-Icon
    width: 48px
    height: 48px
    display: flex
    justify-content: center
    align-items: center
    color: #000
    font-size: 16px
    background-color: #fff
    margin-right: 12px
  .ContactUsInfoTile-Data
    font-size: 16px
    a
      color: #fff
    .Description
      font-size: 14px
      color: var(--darkGray)

@media ( min-width: 1024px ) and (max-width: 1200px)
  .ContactUs-Container
    padding: 0px 32px
    grid-template-columns: repeat(2, 1fr)

@media screen and ( max-width: 1024px )
  .ContactUs-Container
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 48px
    padding: 0px 16px
    .ContactUs-Map
      width: 100%
    .ContactUs-Form
      max-width: 100%

@media screen and ( max-width: 768px )
  .ContactUs-Container
    margin-top: 48px
    grid-template-columns: 1fr
    .ContactUs-Links
      grid-template-columns: 1fr
    .ContactUs-Map
      margin-top: 48px
      text-align: center
    .ContactUs-Form
      justify-self: center
      align-items: center
      text-align: center
